<script lang="ts" setup>
import { onMounted } from "vue";
import { useHelper } from "~/composables/helper";
import type Testimonial from "~/types/Testimonial"; // Properties

// Properties
const props = defineProps({
    userState: String,
});

// Data
const testimonials = ref([] as Testimonial[]);
const onlyVideo = false;
const limit = 6;
testimonials.value = (await useHelper().getTestimonials(props.userState as string, onlyVideo, limit)) as Testimonial[];
const resultCount = testimonials.value ? Object.keys(testimonials.value).length : 0;
if (resultCount < limit) {
    const testimonials2 = await useHelper().getTestimonials("", onlyVideo, limit - resultCount, testimonials.value);
    if (testimonials2) {
        testimonials.value = testimonials.value.concat(testimonials2 as Testimonial[]);
    }
}

onMounted(async () => {
    // swiper element
    const swiperEl = document.querySelector("#customer-say-slides");
    const btnNext = document.querySelector("#customer-say-slides-btn-next");
    const btnPrev = document.querySelector("#customer-say-slides-btn-prev");

    if (!swiperEl) {
        console.log("swiperEl not found - Testimonials Count:", testimonials.value.length);
        return;
    }

    // swiper parameters
    const swiperParams = {
        slidesPerView: 1,
        spaceBetween: 15,
        centeredSlides: true,
        loop: true,
        navigation: {
            nextEl: btnNext,
            prevEl: btnPrev,
        },
        autoplay: {
            delay: 8000,
            disableOnInteraction: true,
            pauseOnMouseEnter: true,
        },
        breakpoints: {
            640: { slidesPerView: 2 },
            768: { slidesPerView: 2 },
            1024: { slidesPerView: 2 },
            1280: { slidesPerView: 3 },
        },
        on: {
            init() {
                // ...
            },
        },
    };

    // now we need to assign all parameters to Swiper element
    Object.assign(swiperEl, swiperParams);

    // and now initialize it
    swiperEl?.initialize();
});
</script>

<template>
    <div v-if="testimonials && testimonials.length > 0">
        <HrWithText class="mb-2 lg:mb-8">
            <h2 class="text-xl font-semibold lg:text-2xl">What Our Customers Have to Say</h2>
        </HrWithText>

        <div class="relative">
            <swiper-container
                id="customer-say-slides"
                init="false"
                class="swiper-container px-2 md:w-[70%] lg:w-[80%] xl:w-[87%]"
            >
                <swiper-slide
                    v-for="(data, index) in testimonials"
                    :key="index"
                    class="my-6 overflow-hidden rounded-2xl border border-gray-300 p-3 shadow-lg lg:p-6"
                >
                    <div class="h-48 overflow-hidden sm:h-28 md:h-32 lg:h-44 xl:h-48">
                        <iframe
                            v-if="data.video_url"
                            :src="data.video_url"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media;
                                    gyroscope; picture-in-picture; web-share"
                            allowfullscreen
                        >
                        </iframe>
                        <div v-else v-html="data.details"></div>
                    </div>
                    <div class="flex min-h-[112px] items-center overflow-hidden pt-4">
                        <div class="m-2 w-12 flex-none overflow-hidden rounded-full">
                            <NuxtImg
                                :src="data.photo ? data.photo.url : '/images/home/Portrait_Placeholder.png'"
                                class="w-full"
                            />
                        </div>
                        <div class="ml-2 flex-1">
                            <div class="font-semibold">
                                {{ data.author }}
                                <span v-if="data.position" class="whitespace-pre text-sm font-normal"
                                    >({{ data.position }})</span
                                >
                            </div>
                            <div v-if="data.organization" class="overflow-hidden whitespace-nowrap">
                                {{ data.organization }}
                            </div>
                            <div v-if="data.location" class="overflow-hidden whitespace-nowrap text-abc-red">
                                {{ data.location }}
                            </div>
                        </div>
                    </div>
                </swiper-slide>
            </swiper-container>

            <div
                class="absolute right-0 top-0 z-10 hidden h-full items-center justify-center border-l-0 bg-white p-4 shadow-xl shadow-white md:flex"
            >
                <div
                    id="customer-say-slides-btn-next"
                    class="flex-none cursor-pointer rounded-full border px-5 py-3 shadow-lg hover:border-2"
                >
                    <img src="/images/fa/chevron-right-regular.svg" class="w-5" />
                </div>
            </div>
            <div
                class="absolute left-0 top-0 z-10 hidden h-full items-center justify-center border-r-0 bg-white p-4 shadow-xl shadow-white md:flex"
            >
                <div
                    id="customer-say-slides-btn-prev"
                    class="flex-none cursor-pointer rounded-full border px-5 py-3 shadow-lg hover:border-2"
                >
                    <img src="/images/fa/chevron-left-regular.svg" class="w-5" />
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
:deep(iframe) {
    @apply h-full w-full;
}
</style>
